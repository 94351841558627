


























































































































  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    // border-top: 5px solid $colorPrimary;
    // border-top-left-radius: 0 !important;
    // border-top-right-radius: 0 !important;

    .v-data-table-header-mobile {
      display: none;
    }
  }

  .box-form {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

.textarea-msg-terminal textarea {
  text-align: center;
}

  
.abas {
  margin-left: 10px;
  padding-top: 10px;
  .v-tab {
    font-size: 14px;
  }

  .abas-item {
    background-color: transparent;
    border: 1px solid $colorPrimary;
    color: $colorPrimary !important;
    padding-top: 2px;
    
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:before {
      display: none;
    }

    &.v-tab--active {
      background-color: $colorPrimary;
      color: #FFF !important;
    }

  }
  .v-tabs .v-tabs-slider-wrapper {
    display: none;
  }
}
